import axios from "axios";
const config = require('../config.js')

// const ROOT_URL  = process.env.ROOT_URL;
const DC_URL = config.DC_URL
// const DC_URL  = "http://localhost:1599";

// export const isDirectCredit = () => {
//     return axios.get(`${DC_URL}/get_alluser`)
// }
// export const addContractDirectCredit = (token,obj) => {
//     return axios.post(`${ROOT_URL}/user/approved`, obj ,{
//         // headers: {
//         //     'Content-Type': 'application/json',
//         //     'Authorization': 'Bearer ' + token
//         // },
//     })
// }
export const getAwaitTransfer = (object, token) => {
    return axios.post(`${DC_URL}/direct_credit/get_await_tranfer`, object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
export const GetExcelData = (data) => {
    return axios.post(`${DC_URL}/direct_credit/get_excel_report`,data,{
        headers : {
            'Content-Type' : 'application/json',
            // 'Authorization' : 'Bearer ' + token
        }
    })
}
export const GetExcelData2 = (data) => {
    return axios.post(`${DC_URL}/direct_credit/get_excel_report2`,data,{
        headers : {
            'Content-Type' : 'application/json',
            // 'Authorization' : 'Bearer ' + token
        }
    })
}
export const getReadyTransfer = (object, token) => {
    return axios.get(`${DC_URL}/direct_credit/get_ready_tranfer`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
export const getFinishTransfer = (object, token) => {
    return axios.post(`${DC_URL}/direct_credit/get_finish_tranfer`, object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
export const getCreditOperationList = (object, token) => {
    return axios.post(`${DC_URL}/direct_credit/get_credit_operation_list`, object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
export const getCreditOperationDetail = (obj, token) => {
    return axios.post(`${DC_URL}/direct_credit/get_credit_operation_detail`, obj, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}



export const transfer = (object, token) => {
    return axios.post(`${DC_URL}/direct_credit/transfer`, object, {
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + token
        },
    })
}
export const checkPIN = (object, token) => {
    return axios.post(`${DC_URL}/direct_credit/check_pin`, object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};

export const update_waive = (object,token) =>{
    console.log(object)
    return axios.post(`${DC_URL}/direct_credit/waive_update`, object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}

export const edit_status = (object, token, callback) => {
    return axios.post(`${DC_URL}/direct_credit/edit_status`, object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
export const sumbit_oper = (object, token) => {
    return axios.post(`${DC_URL}/direct_credit/update_credit_operation`, object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
export const manualPayment = (object, token, callback) => {
    return axios.post(`${DC_URL}/gb/payment`, object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
export const addManualPayment = (object) => {
    console.log('object', object)
    return axios.post(`${DC_URL}/user_manual_payment`, object, {
        headers: {
            'Content-Type': 'application/json'
        },
    })
}

export const editUserDetail = (object) => {
    return axios.post(`${DC_URL}/edit_user_detail`, object, {
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': 'Bearer ' + token
        // },
    })
}
export const getTransactionStatus = (object) => {
    return axios.get(DC_URL + '/get_transaction_status/' + object)
};
export const getTransactionExport = (object) => {
    return axios.post(DC_URL + '/get_transaction_export', object)
};
export const getUserManualPayment = (object) => {
    return axios.get(DC_URL + '/get/user_manual_payment', object)
};